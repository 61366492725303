<template>
  <div class="billing-history">
    <div class="toggle-section">
      <span :class="[activeTab === 'invoice' ? 'active': '']"
      @click="activeTab = 'invoice'">Invoices</span>
      <span :class="[activeTab === 'transaction' ? 'active': '']"
      @click="activeTab = 'transaction'">Transactions</span>
    </div>
    <div class="bill-history__table-wrapper">
      <template v-if="activeTab === 'invoice'">
        <table class="bill">
          <thead>
            <tr>
              <th>Date</th>
              <th>Bill Number</th>
              <th>Amount</th>
              <th></th>
            </tr>
          </thead>
          <tbody v-if="activeProject && activeProject.billingInfo.invoices.length">
            <tr class="bill"
              v-for="(invoice, index) in activeProject.billingInfo.invoices"
              :key="index"
            >
              <td>
                <router-link
                  class="bill__date"
                  :to="{ name: 'invoice', params: { invoicekey: invoice.key }}"
                >
                  {{dateConversion(invoice.generated_date, 'MMM D, YYYY')}}
                </router-link>
              </td>
              <td>
                <router-link
                  class="bill__bill-number"
                  :to="{ name: 'invoice', params: { invoicekey: invoice.key }}"
                >
                  {{invoice.bill_number}}
                </router-link>
              </td>
              <td>
                <router-link
                  class="bill__amount"
                  :to="{ name: 'invoice', params: { invoicekey: invoice.key }}"
                >
                  {{formatCurrency(invoice.grand_total, invoice.currency)}}
                </router-link>
              </td>
              <td class="payment-status">
                <router-link
                  :to="{ name: 'invoice', params: { invoicekey: invoice.key }}"
                >
                  <span class="paid" v-if="invoice.is_fulfilled && !invoice.status.isCancelledInvoice">Paid</span>
                  <span class="unpaid" v-else-if="invoice.status.isCancelledInvoice">Invoice Cancelled</span>
                  <span class="unpaid" v-else>Unpaid</span>
                </router-link>
              </td>
            </tr>
          </tbody>
          <tbody class="empty-state" v-else>
            <tr>
              <td colspan="4">
                <img src="@/assets/chessboard.svg" class="empty-state__image" alt="chess board with two coins"/>
                <p class="empty-state__text"> No Bill Generated </p>
              </td>
            </tr>
          </tbody>
        </table>
        <div class="pagination-wrapper" v-if="activeProject && activeProject.billingInfo
        && activeProject.billingInfo.invoices.length">
          <svg :class="[(activeProject.billingInfo.pagination_params &&
            activeProject.billingInfo.pagination_params.current_page) > 1 ? 'active': 'disabled']"
              @click="changePage(previousPage)"
          >
            <use v-bind="{'xlink:href':'#pagination-left-icon'}"></use>
          </svg>
          <template v-for="page in pageNumbers" :key="page">
            <span :class="{ active: page == (activeProject.billingInfo.pagination_params &&
              activeProject.billingInfo.pagination_params.current_page)}"
              @click="changePage(page)">
              {{page}}
            </span>
          </template>
          <svg
            :class="[(activeProject.billingInfo.pagination_params
            && activeProject.billingInfo.pagination_params.current_page)
              < (activeProject.billingInfo.pagination_params
              && activeProject.billingInfo.pagination_params.total_pages)
            ? 'active': 'disabled']"
            @click="changePage(activeProject.billingInfo.pagination_params.next_page)">
            <use v-bind="{'xlink:href':'#pagination-right-icon'}"></use>
          </svg>
        </div>
      </template>
      <template v-else>
        <TransactionTable
          v-if="activeProject"
          @paginate="navigatePage($event)"
        />
      </template>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex';
import { Invoice } from '@/store/modules/order';
import moment from 'moment';
import Config from '@/config';
import TransactionTable from '@/components/TransactionTable.vue';
import { currencyMethodsMixin } from '@/mixin/currency';

export default {
  mixins: [currencyMethodsMixin],
  name: 'BillingHistory',
  components: {
    TransactionTable,
  },
  props: {
    offset: {
      type: Number,
      default: 2,
    },
  },
  data() {
    return {
      showRecieptDetails: false,
      count: Number,
      activeTab: 'invoice',
      emailSentStatus: false,
      emailSentList: [],
      toolTipVisibility: false,
      isLoading: true,
    };
  },
  computed: {
    ...mapState({
      activeProject: (state) => state.project.activeProject,
    }),
    pageNumbers() {
      let from = (this.activeProject && this.activeProject.billingInfo
      && this.activeProject.billingInfo.pagination_params
      && this.activeProject.billingInfo.pagination_params.current_page) - this.offset;
      if (from < 1) {
        from = 1;
      }
      let nextPage = from + this.offset * 2;
      if (nextPage >= (this.activeProject && this.activeProject.billingInfo
      && this.activeProject.billingInfo.pagination_params
      && this.activeProject.billingInfo.pagination_params.total_pages)) {
        nextPage = this.activeProject.billingInfo.pagination_params
        && this.activeProject.billingInfo.pagination_params.total_pages;
      }
      const pagesArray = [];
      for (let page = from; page <= nextPage; page += 1) {
        pagesArray.push(page);
      }
      return pagesArray;
    },
    previousPage() {
      if (this.activeProject && this.activeProject.billingInfo
      && this.activeProject.billingInfo.pagination_params
      && this.activeProject.billingInfo.pagination_params.current_page > 1) {
        return this.activeProject.billingInfo.pagination_params.current_page - 1;
      }
      return null;
    },
  },
  created() {
    this.fetchTransaction();
    this.fetchInvoice();
  },
  methods: {
    changePage(page) {
      // eslint-disable-next-line vue/require-explicit-emits
      this.$emit('paginate', page);
    },
    navigatePage(page) {
      const query = {};
      query.page = page;
      query.params = 'show_failed=1&show_waiting=1&show_successful=1';
      this.fetchTransaction(query);
    },
    fetchInvoice(page) {
      this.isLoading = true;
      const params = {
        page: page || 1,
        projectKey: this.activeProject.key,
      };
      this.$store.dispatch('project/fetchInvoiceHistory', { params })
        .then(() => {
          this.isLoading = false;
          const currentYear = new Date().getFullYear();
          let currentMonth = new Date().getMonth() + 1;
          if (currentMonth < 10) {
            currentMonth = `0${currentMonth}`;
          }
          this.activeMonth = `${currentYear}-${currentMonth}`;
          params.month = currentMonth;
          params.year = currentYear;
        }).catch(() => {
          console.log('error');
        });
    },
    fetchTransaction(_query) {
      if (this.activeProject.loaded) {
        const query = _query || {};
        query.key = this.activeProject.key;
        if (!query.page) {
          query.page = 1;
        }
        if (!query.params) {
          query.params = 'show_failed=1&show_waiting=1&show_successful=1';
        }
        this.$store.dispatch('project/fetchTransactionHistory', { query });
      }
    },
    dateConversion(date, template) {
      return moment.unix(date).format(template);
    },
    sendEmail(invoiceKey) {
      Invoice.sendInvoiceEmail(invoiceKey).then((resp) => {
        if (resp) {
          this.emailSentStatus = true;
          this.emailSentList.push(invoiceKey);
          this.toolTipVisibility = true;
          setTimeout(() => {
            this.toolTipVisibility = false;
          }, 3000);
        }
      });
    },
    download(invoicekey) {
      const appkey = Config.rzAppKey;
      const apikey = Config.rzApiKey;
      // eslint-disable-next-line max-len
      const url = `/backend/rest/business/invoice/${invoicekey}/?as_pdf=1&rz-api-key=${apikey}&rz-app-key=${appkey}`;
      window.open(url, '_blank');
    },
  },
};
</script>
<style lang="scss" scoped>
.billing-history {
  font-family: var(--global--primary--FontFamily);
  max-width: size(610);
  overflow: hidden;
  .bill-history__table-wrapper {
    overflow-x: auto;
  }
  .toggle-section {
    border-bottom: size(1) solid var(--global--border--Color);
    margin-bottom: size(20);
    span {
      display: inline-block;
      padding-bottom: size(10);
      margin-right: size(24);
      cursor: pointer;
      &.active {
        border-bottom: size(2) solid var(--global--main--title--Color);
      }
      @include font-styles(
        $type: 'label',
        $size: 'lg',
        $weight: 'semibold'
      );
    }
  }
  table{
    background: var(--global--white--Color);
    box-shadow: 0 size(1) size(3) rgba(0, 0, 0, 0.1),
     0 size(2) size(1) rgba(0, 0, 0, 0.06), 0 size(1) size(1) rgba(0, 0, 0, 0.08);
    border-radius: size(8);
    border-collapse: separate;
    padding: 0 size(20) size(20);
    width: 100%;
    text-align: left;
    thead {
      tr {
        th {
          @include font-styles(
            $type: 'label',
            $size: 'lg',
            $weight: 'normal'
          );
          padding: size(20);
          color: var(--global--gray--Color);
          border-bottom: size(1) solid var(--global--border--Color);
          &:first-child {
            padding-left: 0;
          }
          &:last-child {
            padding: size(20) 0;
          }
        }
      }
    }
    tbody {
      tr {
        &:hover {
          cursor: pointer;
          .bill__icon-wrapper {
            display: inline-block;
            vertical-align: top;
            svg {
              fill: var(--global--common-link--Color);
            }
            .mail-sent {
              svg {
                fill: var(--global--success--BackgroundColor);
              }
            }
          }
          td{
            color: var(--global--common-link--Color);
          }
        }
        .bill__icon-wrapper {
          display: none;
          a {
            display: inline-block;
            margin-right: size(20);
            @media screen and (min-width: $breakpoint-lg) {
              margin-right: size(40);
            }
          }
          button {
            cursor: pointer;
            outline: none;
            border: none;
            position: relative;
            &:last-child {
              margin-right: 0;
            }
            .tool-tip {
              display: none;
              position: absolute;
              top: size(25);
              left: size(-5);
              @include font-styles($type: 'label', $size: 'md', $weight: 'normal');
              padding: size(5);
              color: var(--global--success--BackgroundColor);
              min-width: max-content;
              border-radius: size(3);
              box-shadow: size(0) size(1) size(4) rgba(0, 0, 0, 0.15);
              z-index: 1;
              background: var(--global--white--Color);
              &.show {
                display: block;
              }
            }
          }
          button.mail-sent {
            &:hover {
              .tool-tip {
                display: block;
                &.show {
                  display: block;
                }
              }
            }
          }
          svg {
            cursor: pointer;
            &.view-icon {
              width: size(17);
              height: size(12);
            }
            &.mail-icon {
              width: size(16);
              height: size(12);
            }
            &.download-icon {
              width: size(12);
              height: size(12);
            }
          }
        }
        td {
          @include font-styles(
            $type: 'label',
            $size: 'lg',
            $weight: 'normal'
          );
          color: var(--global--gray--Color);
          padding: size(20);
          border-bottom: size(1) solid var(--global--border--Color);
          a {
            color: var(--global--gray--Color);
          }
          &:first-child {
            padding: size(20) 0;
            width: size(115);
          }
          &:nth-child(2n) {
            width: size(220);
          }
          &:nth-child(3n) {
            width: size(150);
          }
          &:last-child {
            padding: size(20) 0;
            width: auto;
          }
        }
        &:last-child {
          td {
            border-bottom: none;
            padding-bottom: 0;
          }
        }
        .bill__date {
          color: var(--global--common-link--Color);
        }
        .bill__amount {
          color: #111;
        }
        .payment-status {
          cursor: pointer;
          @include font-styles(
            $type: 'label',
            $size: 'sm',
            $weight: 'normal'
          );
          span {
            display: inline-block;
            padding: size(2) size(10);
            border-radius: size(6);
          }
          .paid {
            color:  #00A656;
            background: rgba(0, 166, 86, 0.1);
          }
          .unpaid {
            color: #C83739;
            background: linear-gradient(0deg, rgba(200, 55, 57, 0.1), rgba(200, 55, 57, 0.1)), #FFFFFF;
          }
        }
        .bill__icon-wrapper {
          display: none;
          a {
            display: inline-block;
            margin-right: size(20);
            @media screen and (min-width: $breakpoint-lg) {
              margin-right: size(40);
            }
          }
          svg {
            cursor: pointer;
            margin-right: size(20);
            @media screen and (min-width: $breakpoint-lg) {
              margin-right: size(40);
            }
            &:last-child  {
              margin-right: 0;
            }
            &.view-icon {
              width: size(17);
              height: size(12);
            }
            &.mail-icon {
              width: size(16);
              height: size(12);
            }
            &.download-icon {
              width: size(12);
              height: size(12);
            }
          }
        }
      }
    }
  }
  .pagination-wrapper {
    padding: size(40) 0;
    text-align: center;
    svg {
      display: inline-block;
      width: size(15);
      height: size(18);
      cursor: pointer;
      margin-left: size(22);
      vertical-align: top;
      margin-top: size(3);
      fill: var(--global--supportive--Color);
      &.disabled {
        fill: var(--global--supportive--Color);
        cursor: not-allowed;
      }
      &.active {
        fill: var(--global--common-link--Color);
      }
    }
    span {
      @include font-styles(
        $type: 'text',
        $size: 'md',
        $weight: 'normal'
      );
      display: inline-block;
      margin-left: size(22);
      vertical-align: top;
      padding-top: size(3);
      cursor: pointer;
      color: var(--global--supportive--Color);
      &.active {
        color: var(--global--common-link--Color);
      }
    }
  }
  .payment-status {
    @include font-styles(
      $type: 'text',
      $size: 'md',
      $weight: 'normal'
    );
    color: var(--global--main--Color);
    &:hover {
      svg {
        fill: var(--global--common-link--Color)!important;
        stroke: var(--global--common-link--Color)!important;
      }
    }
    svg {
      display: inline-block;
      margin-right: size(10);
    }
    &.waiting_for_payment {
      svg {
        fill: var(--global--common-link--Color);
        stroke: var(--global--common-link--Color);
        width: size(11);
        height: size(11);
      }
    }
    &.payment_transaction_success {
      svg {
        fill: var(--global--success--BackgroundColor);
        stroke: var(--global--success--BackgroundColor);
        width: size(11);
        height: size(8);
      }
    }
    &.payment_transaction_failed, .payment_delayed {
      svg {
        fill: var(--global--error--BackgroundColor);
        stroke: var(--global--error--BackgroundColor);
        width: size(9);
        height: size(9);
      }
    }
  }
  .empty-state {
    height: size(250);
    &__image {
      display: block;
      padding-top: size(40);
      margin-left: auto;
      margin-right: auto;
    }
    &__text {
      text-align: center;
      cursor: alias;
      color: var(--global--main--title--Color);
      @include font-styles(
        $type: 'text',
        $size: 'lg',
        $weight: 'regular'
      );
      padding: size(20) 0;
    }
  }
}
</style>
