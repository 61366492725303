<template>
  <div class="transaction-table-wrapper">
    <div class="transaction__table">
      <table>
        <thead>
          <tr>
            <th>Date</th>
            <th>Receipt Number</th>
            <th>Amount</th>
            <th></th>
          </tr>
        </thead>
        <tbody v-if="activeProject && activeProject.transactionInfo
         && activeProject.transactionInfo.transactions.length">
          <template  v-for="(transaction, index) in (activeProject && activeProject.transactionInfo
            && activeProject.transactionInfo.transactions)" :key="index">
            <tr :class="[transaction.notes ? 'table-row' : '']">
              <td>
                <router-link
                  class="transaction__date"
                  :to="{ name: 'transaction', params: { transactionkey: transaction.key }}"
                >
                  {{$filters.format(transaction.created_at)}}
                </router-link>
              </td>
              <td>
                <router-link
                  class="transaction__number"
                  :to="{ name: 'transaction', params: { transactionkey: transaction.key }}"
                >
                  {{transaction.key}}
                </router-link>
              </td>
              <td>
                <router-link
                  class="transaction__amount"
                  :to="{ name: 'transaction', params: { transactionkey: transaction.key }}"
                >
                  {{ formatCurrency(transaction.amount, transaction.currency)}}
                  <span v-if="transaction.kind !== 'money'">
                    <svg>
                      <use v-bind="{ 'xlink:href': '#virtual-money-icon' }" />
                    </svg>
                  </span>
                </router-link>
              </td>
              <td class="transaction__status">
                <router-link
                  class="transaction__status"
                  :to="{ name: 'transaction', params: { transactionkey: transaction.key }}"
                >
                  <span v-if="transaction.status.isWaitingForPayment ||
                      transaction.status.isPaymentFailed" class="unsuccessful">
                    Unsuccessful
                  </span>
                  <span v-else class="successful">
                    Successful
                  </span>
                </router-link>
              </td>
            </tr>
            <tr class="notes-row" v-if="transaction.notes">
              <td colspan="4">
                <p v-if="transaction.notes">
                  <svg>
                    <use v-bind="{ 'xlink:href': '#notes-yellow' }" />
                  </svg> {{transaction.notes}}</p>
              </td>
            </tr>
          </template>
        </tbody>
        <tbody class="empty-state" v-else>
          <tr>
            <td colspan="4">
              <img src="@/assets/chessboard.svg" class="empty-state__image" alt="chess board with two coins"/>
              <p class="empty-state__text"> No Transactions Done </p>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="pagination-wrapper" v-if="activeProject && activeProject.transactionInfo
     && activeProject.transactionInfo.transactions.length">
      <svg :class="[(activeProject.transactionInfo.pagination_params &&
        activeProject.transactionInfo.pagination_params.current_page) > 1 ? 'active': 'disabled']"
        @click="changePage(previousPage)"
      >
        <use v-bind="{'xlink:href':'#pagination-left-icon'}"></use>
      </svg>
      <template v-for="page in pageNumbers" :key="page">
        <span :class="{ active: page == (activeProject.transactionInfo.pagination_params &&
        activeProject.transactionInfo.pagination_params.current_page)}"
          @click="changePage(page)">
          {{page}}
        </span>
      </template>
      <svg
        :class="[(activeProject.transactionInfo.pagination_params
        && activeProject.transactionInfo.pagination_params.current_page)
          < (activeProject.transactionInfo.pagination_params
          && activeProject.transactionInfo.pagination_params.total_pages)
        ? 'active': 'disabled']"
        @click="changePage(activeProject.transactionInfo.pagination_params.next_page)">
        <use v-bind="{'xlink:href':'#pagination-right-icon'}"></use>
      </svg>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.transaction-table-wrapper {
  .transaction__table {
    padding: 0 size(20);
    background: var(--global--white--Color);
    box-shadow: 0 size(1) size(3) rgba(0, 0, 0, 0.1),
     0 size(2) size(1) rgba(0, 0, 0, 0.06), 0 size(1) size(1) rgba(0, 0, 0, 0.08);
    border-radius: size(8);
    padding-bottom: size(20);
    table{
      border-collapse: separate;
      width: 100%;
      text-align: left;
      thead {
        tr {
          th {
            @include font-styles(
              $type: 'label',
              $size: 'lg',
              $weight: 'normal'
            );
            color: var(--global--gray--Color);
            border-bottom: size(1) solid var(--global--border--Color);
            padding: size(20);
            &:first-child {
              padding-left: 0;
            }
            &:last-child {
              padding-right: 0
            }
          }
        }
      }
      tbody {
        tr {
          &:hover {
            cursor: pointer;
            .transaction__icon-wrapper {
              display: inline-block;
              vertical-align: top;
              svg {
                fill: var(--global--common-link--Color);
              }
              .mail-sent {
                svg {
                  fill: var(--global--success--BackgroundColor);
                }
              }
            }
            td{
              color: var(--global--common-link--Color);
              .unpaid, .paid {
                span {
                  color: var(--global--common-link--Color);
                }
              }
            }
          }
          td {
            @include font-styles(
              $type: 'label',
              $size: 'lg',
              $weight: 'normal'
            );
            color: var(--global--gray--Color);
            padding: size(20);
            border-bottom: size(1) solid var(--global--border--Color);
            &:first-child {
              padding: size(20) 0;
              width: size(115);
            }
            &:nth-child(2n) {
              width: size(220);
            }
            &:nth-child(3n) {
              width: size(150);
            }
            &:last-child {
              padding: size(20) 0;
              width: auto;
            }
            a {
              color: var(--global--gray--Color);
            }
            span {
              display: inline-block;
              vertical-align: middle;
              padding: size(2) size(10);
              border-radius: size(6);
              @include font-styles(
                $type: 'label',
                $size: 'sm',
                $weight: 'normal'
              );
            }
            p {
              @include font-styles(
                $type: 'label',
                $size: 'sm',
                $weight: 'normal'
              );
              color: var(--global--main--title--Color);
              padding-top: size(5);
            }
            .successful {
              color:  #00A656;
              background: rgba(0, 166, 86, 0.1);
            }
            .unsuccessful {
              color: #C83739;
              background: linear-gradient(0deg, rgba(200, 55, 57, 0.1), rgba(200, 55, 57, 0.1)), #FFFFFF;
            }
          }
          &.table-row {
            td {
              padding: size(20) size(20) 0;
              border: none;
              &:first-child {
                padding: size(20) 0 0;
              }
              &:last-child {
                padding: size(20) 0 0;
                width: auto;
              }
            }
          }
          &.notes-row {
            td {
              border-bottom: size(1) solid var(--global--border--Color);
              padding: size(10) 0 size(20);
              p {
                background: #FFFFC7;
                border-radius: size(6);
                padding: size(10);
              }
              svg {
                display: inline-block;
                vertical-align: middle;
                margin-right: size(8);
                width: size(18);
                height: size(18);
                fill: #F0F06D;
                stroke: #F0F06D;
              }
            }
          }
          &:last-child {
            td {
              border-bottom: none;
              padding-bottom: 0;
            }
          }
          .transaction__date{
            color: var(--global--common-link--Color);
          }
          .transaction__amount {
            color: #111;
            svg {
              width: size(25);
              height: size(25);
              display: inline-block;
              vertical-align: text-bottom;
              fill: var(--global--virtual-icon--BackgroundColor);
              stroke: var(--global--white--Color);
            }
          }
          .transaction__icon-wrapper {
            display: none;
            a {
              display: inline-block;
              margin-right: size(20);
              @media screen and (min-width: $breakpoint-lg) {
                margin-right: size(40);
              }
            }
            button {
              cursor: pointer;
              outline: none;
              border: none;
              margin-right: size(20);
              position: relative;
              &:last-child {
                margin-right: 0;
              }
              @media screen and (min-width: $breakpoint-lg) {
                margin-right: size(40);
              }
              .tool-tip {
                display: none;
                position: absolute;
                top: size(25);
                left: size(20);
                @include font-styles($type: 'label', $size: 'md', $weight: 'normal');
                padding: size(5);
                color: var(--global--success--BackgroundColor);
                min-width: max-content;
                border-radius: size(3);
                box-shadow: size(0) size(1) size(4) rgba(0, 0, 0, 0.15);
                z-index: 1;
                background: var(--global--white--Color);
                &.show {
                  display: block;
                }
              }
            }
            button.mail-sent {
              &:hover {
                .tool-tip {
                  display: block;
                  &.show {
                    display: block;
                  }
                }
              }
            }
            &> svg {
              margin-right: size(20);
              @media screen and (min-width: $breakpoint-lg) {
                margin-right: size(40);
              }
              &:last-child  {
                margin-right: 0;
              }
            }
            svg {
              cursor: pointer;
              &.view-icon {
                width: size(17);
                height: size(12);
              }
              &.mail-icon {
                width: size(16);
                height: size(12);
              }
              &.download-icon {
                width: size(12);
                height: size(12);
              }
            }
          }
        }
      }
    }
  }
  .pagination-wrapper {
    padding: size(40) 0;
    text-align: center;
    svg {
      display: inline-block;
      vertical-align: top;
      margin-top: size(3);
      width: size(15);
      height: size(18);
      cursor: pointer;
      margin-left: size(22);
      fill: var(--global--supportive--Color);
      &.disabled {
        fill: var(--global--supportive--Color);
        cursor: not-allowed;
      }
      &.active {
        fill: var(--global--common-link--Color);
      }
    }
    span {
      @include font-styles(
        $type: 'text',
        $size: 'md',
        $weight: 'normal'
      );
      display: inline-block;
      margin-left: size(22);
      vertical-align: top;
      padding-top: size(3);
      cursor: pointer;
      color: var(--global--supportive--Color);
      &.active {
        color: var(--global--common-link--Color);
      }
    }
  }
  .empty-state {
    height: size(250);
    &__image {
      display: block;
      padding-top: size(40);
      margin-left: auto;
      margin-right: auto;
    }
    p {
      &.empty-state__text {
        text-align: center;
        cursor: alias;
        color: var(--global--main--title--Color);
        @include font-styles(
          $type: 'text',
          $size: 'lg',
          $weight: 'regular'
        );
        padding: size(20) 0;
      }
    }
  }
}
</style>

<script>
import { mapState } from 'vuex';
import { currencyMethodsMixin } from '@/mixin/currency';
import { Transaction } from '@/store/modules/order';
import Config from '@/config';

export default {
  name: 'TransactionTable',
  components: {},
  mixins: [currencyMethodsMixin],
  props: {
    offset: {
      type: Number,
      default: 2,
    },
  },
  data() {
    return {
      count: Number,
      emailSentStatus: false,
      emailSentList: [],
      toolTipVisibility: false,
      isLoading: true,
    };
  },
  computed: {
    ...mapState({
      activeProject: (state) => state.project.activeProject,
    }),
    pageNumbers() {
      let from = (this.activeProject && this.activeProject.transactionInfo
      && this.activeProject.transactionInfo.pagination_params
      && this.activeProject.transactionInfo.pagination_params.current_page) - this.offset;
      if (from < 1) {
        from = 1;
      }
      let nextPage = from + this.offset * 2;
      if (nextPage >= (this.activeProject && this.activeProject.transactionInfo
      && this.activeProject.transactionInfo.pagination_params
      && this.activeProject.transactionInfo.pagination_params.total_pages)) {
        nextPage = this.activeProject.transactionInfo.pagination_params
        && this.activeProject.transactionInfo.pagination_params.total_pages;
      }
      const pagesArray = [];
      for (let page = from; page <= nextPage; page += 1) {
        pagesArray.push(page);
      }
      return pagesArray;
    },
    previousPage() {
      if (this.activeProject && this.activeProject.transactionInfo
      && this.activeProject.transactionInfo.pagination_params
      && this.activeProject.transactionInfo.pagination_params.current_page > 1) {
        return this.activeProject.transactionInfo.pagination_params.current_page - 1;
      }
      return null;
    },
  },
  methods: {
    changePage(page) {
      this.$emit('paginate', page);
    },
    sendEmail(transactionKey) {
      Transaction.sendTransactionEmail(transactionKey).then((resp) => {
        if (resp) {
          this.emailSentStatus = true;
          this.emailSentList.push(transactionKey);
          this.toolTipVisibility = true;
          setTimeout(() => {
            this.toolTipVisibility = false;
          }, 3000);
        }
      });
    },
    download(transactionkey) {
      const appkey = Config.rzAppKey;
      const apikey = Config.rzApiKey;
      // eslint-disable-next-line max-len
      const url = `/backend/rest/business/transaction/${transactionkey}/?as_pdf=1&rz-api-key=${apikey}&rz-app-key=${appkey}`;
      window.open(url, '_blank');
    },
  },
};
</script>
