<template>
  <div class="billing-section" v-if="!isPackage">
    <h1 class="title">Billing</h1>
    <div class="discount-promo" v-if="activeProject.minimum_lifetime_discount_amount === null
      && activeProject.lifetime_discount_percentage && (!activeProject.billing ||
      !activeProject.billing.is_verified)">
      <p>
        Verify your billing details to unlock exclusive offers!</p>
    </div>
    <div class="billing-section__current-status">
      <div class="align-left">
        <div class="pending-bills">
          <h3>Pending Bills</h3>
          <div :class="[activeProject && activeProject.unpaidBillInfo &&
            activeProject.unpaidBillInfo.invoices && activeProject.unpaidBillInfo.invoices.length === 0
            ? 'hide' : '']"
            v-if="activeProject.loaded && activeProject.unpaidBillInfo
            && activeProject.unpaidBillInfo.invoices.length > 0">
            <div class="wrapper">
              <div v-for="info in activeProject.unpaidBillInfo.invoices" :key="info.key">
                <div class="content-wrapper">
                  <p class="amount">{{formatCurrency(info.grand_total, info.currency)}}</p>
                  <p class="status">Pending</p>
                </div>
                <div class="content-wrapper">
                  <p class="period">Period</p>
                  <p>{{$filters.formatMonth(info.generated_date)}}</p>
                </div>
                <router-link
                  class="view-bill-btn"
                  :to="{ name: 'invoice', params: { invoicekey: info.key, } }">
                  View Bill
                </router-link>
                <a
                  class="btn-style-common medium"
                  :href="link+'user/project/'+activeProject.key+'/wizard?step=payment&invoice='
                  +info.key"
                  target="_blank">Pay</a>
              </div>
            </div>
          </div>
          <div v-else>
            <div class="wrapper no-bills">
              <p>Yayyy! No Pending bills!</p>
            </div>
          </div>
        </div>
        <PrioritySupport
          :layout-type="'dual-column'"
          v-if="activeProject.project_license"
        />
        <div class="billing-section__billing-history">
          <billing-history v-if="activeProject && activeProject.billingInfo && !isLoading"
            @paginate="fetchData($event)"
          ></billing-history>
          <div class="loader" v-else>
            <LoadingPlaceholder />
          </div>
        </div>
      </div>
      <div class="align-right">
        <div class="cash-balance-wrapper"
          :class="[activeProject.cashDetails.cash_balance === null
          && activeProject.billing_method === 'pay_by_invoice' ? 'hide' : '']"
          v-if="activeProject.cashDetails && activeProject.loaded">
          <div class="empty-cash-balance" v-if="activeProject.cashDetails.cash_balance === null
            && (activeProject.billing_method === 'unknown' || activeProject.billing_method === 'prepay')"
          >
            <div class="content-div" v-if="activeProject.project_license.license_key.isStandard">
              <h4>Cash Balance</h4>
              <a target="_blank" class="btn-style-common outlined medium"
                :href="activeProject.setUpfrontLink" title="Load money">Load Money
              </a>
            </div>
            <div class="button-wrapper">
              <div class="committed-usage-block">
                <h3>Introducing GraphQL for Cricket</h3>
                <p>Achieve all that is needed simply in just a single API request.</p>
                <a target="_blank"
                  :href="graphqlLink" title="Committed use"
                  class="btn-style-common medium"
                >
                  Explore
                </a>
              </div>
            </div>
          </div>
          <div class="cash-balance-div" v-if="activeProject.cashDetails && activeProject.cashDetails.cash_balance">
            <div class="wrapper">
              <h4>Cash Balance</h4>
              <p>As of {{$filters.utcLocalDate(activeProject.cashDetails.cash_balance.last_updated_date)}}</p>
              <p class="amount">
                {{formatCurrency(activeProject.cashDetails.cash_balance.balance,
                activeProject.cashDetails.cash_balance.currency)}}
              </p>
              <a target="_blank" class="btn-style-common outlined medium load-money"
                v-if="activeProject.billing_method !== 'pay_by_invoice'
                && activeProject.project_license.license_key.isStandard"
                :href="activeProject.setUpfrontLink" title="Load money">
                Load Money</a>
            </div>
            <div class="button-wrapper">
              <div class="committed-usage-block">
                <h3>Introducing GraphQL for Cricket</h3>
                <p>Achieve all that is needed simply in just a single API request.</p>
                <a target="_blank"
                  :href="graphqlLink" title="Committed use"
                  class="btn-style-common medium"
                >
                  Explore
                </a>
              </div>
            </div>
          </div>
          <div class="billed-usage-div" v-if="activeProject.cashDetails && activeProject.cashDetails.billed_usage">
            <h4>Billed usage</h4>
            <span>
              <span v-if="activeProject.cashDetails.billed_usage.from_date">
                {{$filters.utcLocalDate(activeProject.cashDetails.billed_usage.from_date)}}</span>
              <span v-if="activeProject.cashDetails.billed_usage.to_date !==null">
                - {{$filters.utcLocalDate(activeProject.cashDetails.billed_usage.to_date)}}</span>
            </span>
            <p v-if="activeProject.cashDetails.billed_usage.cost >= 0" class="amount">
              {{formatCurrency(activeProject.cashDetails.billed_usage.cost,
              activeProject.cashDetails.billed_usage.currency)}}
            </p>
          </div>
          <div class="unbilled-usage-div" v-if="activeProject.cashDetails
            && activeProject.cashDetails.unbilled_usage">
            <h4>Unbilled usage</h4>
            <span>
              From <span  v-if="activeProject.cashDetails.unbilled_usage.from_date">
              {{$filters.utcLocalDate(activeProject.cashDetails.unbilled_usage.from_date)}}</span>
              <span v-if="activeProject.cashDetails.unbilled_usage.to_date !== null">
              - {{$filters.utcLocalDate(activeProject.cashDetails.unbilled_usage.to_date)}}</span>
            </span>
            <p v-if="activeProject.cashDetails.unbilled_usage.cost >= 0" class="amount">
              {{formatCurrency(activeProject.cashDetails.unbilled_usage.cost,
              activeProject.cashDetails.unbilled_usage.currency)}}
            </p>
          </div>
        </div>
        <div class="loader pending-bills" v-else>
          <LoadingPlaceholder />
        </div>
        <div class="billing-details">
          <div
            class="setup-billing-wrapper"
            v-if="!activeProject.billing || (activeProject.billing && !activeProject.billing.is_verified)"
          >
            <h4 class="sub-header"> Billing Details
              <span v-if="activeProject.billing && (activeProject.billing && !activeProject.billing.is_verified)
                && activeProject.billing.preferred_payment_medium === 'invoice'
                " class="under-review">
                <svg>
                  <use v-bind="{ 'xlink:href': '#help-icon'}" />
                </svg>
                Under Review
              </span>
              <span v-if="activeProject.billing && (activeProject.billing && !activeProject.billing.is_verified)
                && activeProject.billing.preferred_payment_medium !== 'invoice'
                " class="failed">
                <svg>
                  <use v-bind="{ 'xlink:href': '#info-icon'}" />
                </svg>
                Failed
              </span>
            </h4>
            <div>
              <p class="info" v-if="activeProject.billing &&
                (activeProject.billing && !activeProject.billing.is_verified)
                && activeProject.billing.preferred_payment_medium === 'invoice'">
                Your billing profile is under verification. This shouldn't take long, however
                if you are not willing to wait, you can always go ahead and change your payment method.
              </p>
              <p class="info" v-if="(activeProject.billing &&
                (activeProject.billing && !activeProject.billing.is_verified)
                && activeProject.billing.preferred_payment_medium !== 'invoice') ||
                (!activeProject.billing || activeProject.billing === null)">
                <template v-if="!activeProject.project_license.license_key.isEnterpriseAnnual
                && !activeProject.project_license.license_key.isPremiumAnnual">
                  Please set up billing profile to access live sports data
                </template>
                <template v-else>
                  Complete your Pending Payment for Annual Plan
                </template>
              </p>
            </div>
            <div v-if="activeProject.billing" class="billing-section">
              <div class="payment-details">
                <p>
                  <img src="@/assets/stripe-logo.png"
                    v-if="activeProject.billing.preferred_payment_gateway === 'stripe'"/>
                  <img src="@/assets/razorpay-logo.png"
                    v-else-if="activeProject.billing.preferred_payment_gateway === 'razorpay'" />
                  <img v-else src="@/assets/invoice.svg" />
                </p>
                <p>
                  <span>Payment Method:</span>
                  <span>{{activeProject.billing.preferred_payment_medium}}</span>
                </p>
                <p>
                  <span>Payment Gateway:</span>
                  <span>{{activeProject.billing.preferred_payment_gateway}}</span>
                </p>
                <p>
                  <span>Region:</span>{{activeProject.billing.country}}, <span class="currency">
                    {{activeProject.billing.billing_currency}}</span>
                </p>
                <p v-if="activeProject.lifetime_discount_percentage
                  && activeProject.minimum_lifetime_discount_amount !== null">
                    <span>Discount Percentage*:</span>
                    {{activeProject.lifetime_discount_percentage}}%
                </p>
                <p v-if="activeProject.next_future_payment_billing_date" class="billing-date">
                  Next billing on {{$filters.format(activeProject.next_future_payment_billing_date)}}
                </p>
              </div>
              <div class="company-details" v-if="activeProject.billing.company_name">
                <h5>Company</h5>
                <p class="name" v-if="activeProject.billing.company_name">
                  {{activeProject.billing.company_name}}
                </p>
                <p v-if="activeProject.billing.gst_number">
                  <span>GST number:</span> <span>{{activeProject.billing.gst_number}}</span>
                </p>
              </div>
              <div class="contact-details">
                <h5>Billing Contacts</h5>
                <p v-if="activeProject.billing.contact_name" class="name">
                  {{activeProject.billing.contact_name}}</p>
                <a :href="'mailto:'+activeProject.billing.contact_email"
                  class="email"
                  v-if="activeProject.billing.contact_email">
                  {{activeProject.billing.contact_email}}
                </a>
                <p v-if="activeProject.billing.address">
                  <span class="address">{{ activeProject.billing.address }}
                    <br />
                    {{ activeProject.billing.city }}, {{ activeProject.billing.state }}
                    <br />
                    {{ activeProject.billing.country }}, {{ activeProject.billing.zip_code }}
                    <br />
                  </span>
                </p>
              </div>
            </div>
            <div>
              <div v-if="(activeProject.billing && (activeProject.billing && !activeProject.billing.is_verified)
                && activeProject.billing.preferred_payment_medium !== 'invoice')
                || (!activeProject.billing || activeProject.billing === null)">
                <template v-if="!activeProject.project_license.license_key.isEnterpriseAnnual
                  && !activeProject.project_license.license_key.isPremiumAnnual">
                  <a
                    :href="link+'user/project/'+activeProject.key+'/wizard?step=billing'"
                    class="btn-style-common medium"
                    target="_blank"
                  >Set up Billing Profile</a>
                </template>
                <template v-else>
                  <a
                    :href="
                    link+'user/project/'+activeProject.key+'/wizard?step=payment&prepay='
                    +activeProject.project_license.product+'|50'"
                    class="btn-style-common medium"
                    target="_blank"
                  >Pay</a>
                </template>
              </div>
              <template v-if="activeProject.billing && (activeProject.billing && !activeProject.billing.is_verified)
                && activeProject.billing.preferred_payment_medium === 'invoice'">
                <a
                  v-if="!activeProject.project_license.license_key.isEnterpriseAnnual
                  && !activeProject.project_license.license_key.isPremiumAnnual"
                  :href="link+'user/project/'+activeProject.key+'/wizard?step=payment'"
                  class="btn-style-common medium"
                  target="_blank"
                >Change payment Method</a>
                <a
                  v-else
                  :href="link+'user/project/'+activeProject.key+'/wizard?step=payment&prepay='
                    +activeProject.project_license.product+'|50'"
                  class="btn-style-common medium"
                  target="_blank"
                >Change payment Method</a>
              </template>
            </div>
          </div>
          <div
            class="setup-billing-wrapper"
            v-else-if="activeProject.billing && activeProject.billing.is_verified"
          >
            <h4 class="sub-header"> Billing Details
              <span v-if="activeProject.billing && (activeProject.billing && activeProject.billing.is_verified)
                " class="verified">
                <svg>
                  <use v-bind="{ 'xlink:href': '#tickmark-icon'}" />
                </svg>
              </span>
            </h4>
            <div v-if="activeProject.billing" class="billing-section">
              <div class="payment-details">
                <p>
                  <img src="@/assets/stripe-logo.png"
                    v-if="activeProject.billing.preferred_payment_gateway === 'stripe'" />
                  <img src="@/assets/razorpay-logo.png"
                    v-else-if="activeProject.billing.preferred_payment_gateway === 'razorpay'" />
                  <img v-else src="@/assets/invoice.svg" />
                </p>
                <p>
                  <span>Payment Method:</span>
                  <span>{{activeProject.billing.preferred_payment_medium}}</span>
                </p>
                <p>
                  <span>Payment Gateway:</span>
                  <span>{{activeProject.billing.preferred_payment_gateway}}</span>
                </p>
                <p>
                  <span>Region:</span>{{activeProject.billing.country}}, <span class="currency">
                    {{activeProject.billing.billing_currency}}</span>
                </p>
                <p v-if="activeProject.lifetime_discount_percentage
                  && activeProject.minimum_lifetime_discount_amount !== null">
                    <span>Discount Percentage*:</span>
                      {{activeProject.lifetime_discount_percentage}}%
                </p>
                <p v-if="activeProject.next_future_payment_billing_date" class="billing-date">
                  Next billing on {{$filters.format(activeProject.next_future_payment_billing_date)}}
                </p>
              </div>
              <div class="company-details" v-if="activeProject.billing.company_name">
                <h5>Company</h5>
                <p class="name" v-if="activeProject.billing.company_name">
                  {{activeProject.billing.company_name}}
                </p>
                <p v-if="activeProject.billing.gst_number">
                  <span>GST number:</span> <span>{{activeProject.billing.gst_number}}</span>
                </p>
              </div>
              <div class="contact-details">
                <h5>Billing Contacts</h5>
                <p v-if="activeProject.billing.contact_name" class="name">
                  {{activeProject.billing.contact_name}}</p>
                <a class="email" :href="'mailto:'+activeProject.billing.contact_email"
                  v-if="activeProject.billing.contact_email">
                  {{activeProject.billing.contact_email}}</a>
                <p v-if="activeProject.billing.address">
                  <span class="address">{{ activeProject.billing.address }}
                    <br />
                    {{ activeProject.billing.city }}, {{ activeProject.billing.state }}
                    <br />
                    {{ activeProject.billing.country }}, {{ activeProject.billing.zip_code }}
                    <br />
                  </span>
                </p>
              </div>
              <div>
                <a
                  :href="link+'user/project/'+activeProject.key+'/wizard?step=billing'"
                  class="btn-style-common medium"
                  target="_blank"
                >Edit billing profile</a>
              </div>
            </div>
          </div>
          <p class="terms-and-conditions"
            v-if="activeProject.minimum_lifetime_discount_amount !== null
            && activeProject.lifetime_discount_percentage">
            *T &amp; C: Your monthly bill should be
            <span>
              {{formatCurrency(activeProject.minimum_lifetime_discount_amount,
               activeProject.billing.billing_currency) }}
            </span>
            and above to avail <span>{{activeProject.lifetime_discount_percentage}}%.</span>
          </p>
        </div>
      </div>
    </div>
  </div>
  <div class="billing-section full-width" v-else>
    <h1 class="title">Billing</h1>
    <div class="billing-section__current-status">
      <div class="align-left">
        <div class="billing-section__billing-history">
          <billing-history v-if="activeProject && activeProject.billingInfo && !isLoading"
            @paginate="fetchData($event)"
          ></billing-history>
          <div class="loader" v-else>
            <LoadingPlaceholder />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style lang="scss" scoped>
.billing-section {
  .title {
    @include font-styles($type: 'display', $size: 'xs', $weight: 'bold');
    padding-bottom: size(30);
  }
  .discount-promo {
    background: #FFC220;;
    padding: size(15);
    text-align: center;
    @include font-styles($type: 'label', $size: 'lg', $weight: 'semibold');
    margin-bottom: size(30);
    border-radius: size(8);
  }
  .billing-section__current-status {
    display: flex;
    justify-content: space-between;
    margin-bottom: size(50);
    .align-left {
      width: size(610);
      .pending-bills {
        margin-bottom: size(30);
        h3 {
          @include font-styles(
            $type: 'text',
            $size: 'lg',
            $weight: 'bold'
          );
          padding-bottom: size(14);
        }
        .wrapper {
          background: var(--global--white--Color);
          box-shadow: 0 size(1) size(3) rgba(0, 0, 0, 0.1),
          0 size(2) size(1) rgba(0, 0, 0, 0.06), 0 size(1) size(1) rgba(0, 0, 0, 0.08);
          border-radius: size(8);
          padding: 0 size(20);
          &> div {
            padding: size(20) 0;
            border-bottom: size(1) solid var(--global--border--Color);
            &:last-child {
              border-bottom: none;
            }
            .content-wrapper {
              display: inline-block;
              vertical-align: top;
              padding-right: size(135);
              @include font-styles(
                $type: 'label',
                $size: 'lg',
                $weight: 'normal'
              );
              &:first-child {
                padding-right: 0;
                margin-right: size(30);
                width: size(188);
              }
              .amount {
                @include font-styles(
                  $type: 'text',
                  $size: 'md',
                  $weight: 'bold'
                );
                padding-bottom: size(6);
              }
              .status {
                display: inline-block;
                padding: size(2) size(6);
                border-radius: size(6);
                color: #C83739;
                @include font-styles(
                  $type: 'label',
                  $size: 'sm',
                  $weight: 'normal'
                );
                background: linear-gradient(0deg, rgba(200, 55, 57, 0.1), rgba(200, 55, 57, 0.1)), #FFFFFF;
              }
              .period {
                @include font-styles(
                  $type: 'label',
                  $size: 'sm',
                  $weight: 'normal'
                );
                color: var(--global--gray--Color);
                padding-bottom: size(6);
              }
            }
          }
        }
      }
      .no-bills {
        &.wrapper {
          background: url('../assets/vault-img.png') no-repeat 88%;
          background-size: size(44);
          background-color: var(--global--white--Color);
          box-shadow: 0 size(1) size(3) rgba(0, 0, 0, 0.1),
            0 size(2) size(1) rgba(0, 0, 0, 0.06), 0 size(1) size(1) rgba(0, 0, 0, 0.08);
          border-radius: size(8);
          background-position: top size(20) right size(20);
          padding: size(20);
          min-height: size(100);
          p {
            @include font-styles(
              $type: 'label',
              $size: 'lg',
              $weight: 'semibold'
            );
            padding-top: size(18);
          }
        }
      }
    }
    .align-right {
      width: size(380);
      .loader {
        &.pending-bills {
          border: none;
          padding-bottom: size(15);
          background: none;
          .loading-placeholder {
            max-height: size(150);
            margin-top: 0;
          }
        }
      }
      &>div {
        p {
          @include font-styles(
            $type: 'text',
            $size: 'md',
            $weight: 'regular'
          );
        }
      }
      .cash-balance-wrapper {
        &.hide {
          border: none;
        }
        .empty-cash-balance {
          background: url('../assets/vault-img.png') no-repeat 88%;
          background-size: size(44);
          background-color: var(--global--white--Color);
          box-shadow: 0 size(1) size(3) rgba(0, 0, 0, 0.1),
           0 size(2) size(1) rgba(0, 0, 0, 0.06), 0 size(1) size(1) rgba(0, 0, 0, 0.08);
          border-radius: size(8);
          background-position: top size(20) right size(20);
          .content-div {
            margin: 0 size(20);
            padding: size(20) 0;
            border-bottom: size(1) solid var(--global--border--Color);
            .btn-style-common {
              margin-top: size(8);
            }
          }
        }
        .cash-balance-div {
          background: url('../assets/vault-img.png') no-repeat 88%;
          background-size: size(44);
          background-color: var(--global--white--Color);
          box-shadow: 0 size(1) size(3) rgba(0, 0, 0, 0.1),
           0 size(2) size(1) rgba(0, 0, 0, 0.06), 0 size(1) size(1) rgba(0, 0, 0, 0.08);
          border-radius: size(8);
          background-position: top size(20) right size(20);
          .wrapper {
            padding: size(20) 0;
            border-bottom: size(1) solid var(--global--border--Color);
            margin: 0 size(20);
          }
        }
        .billed-usage-div , .unbilled-usage-div {
          padding: size(20);
          background-color: var(--global--white--Color);
          box-shadow: 0 size(1) size(3) rgba(0, 0, 0, 0.1),
           0 size(2) size(1) rgba(0, 0, 0, 0.06), 0 size(1) size(1) rgba(0, 0, 0, 0.08);
          border-radius: size(8);
          margin-top: size(20);
          a {
            background: none;
            color: var(--global--heading--Color);
            padding: 0;
            &:hover {
              color: var(--global--common-link--Color);
            }
          }
        }
        .button-wrapper {
          border: 0;
          padding: 0;
          a {
            margin-top: size(10);
            &.committed-use-btn {
              background: none;
              display: block;
              width: size(201);
              margin-top: size(20);
              padding: 0;
              text-decoration: underline;
              color: var(--global--common-link--Color);
            }
          }
          .committed-usage-block {
            background: url('../assets/cash-balance-bg.png') no-repeat 100%;
            background-size: size(130);
            background-position: bottom right;
            padding: size(20);
            display: none;
            .btn-style-common {
              background: #be3146;
              border: size(1) solid #be3146;
            }
            h3 {
              span {
                color: #5141BD;
                @include font-styles(
                  $type: 'text',
                  $size: 'md',
                  $weight: 'bold'
                );
              }
              @include font-styles(
                $type: 'text',
                $size: 'md',
                $weight: 'bold'
              );
              color: var(--global--main--title--Color);
              padding-bottom: size(10);
            }
          }
          p {
            max-width: size(295);
          }
        }
        h4 {
          @include font-styles(
            $type: 'label',
            $size: 'sm',
            $weight: 'bold'
          );
          margin-bottom: size(4);
          color: var(--global--main--title--Color);
        }
        p {
          @include font-styles(
            $type: 'label',
            $size: 'md',
            $weight: 'regular'
          );
          color: var(--global--heading--Color);
          &.amount {
            margin-top: size(10);
            @include font-styles(
              $type: 'display',
              $size: 'xs',
              $weight: 'bold'
            );
            color: var(--global--main-title--Color);
          }
        }
        .load-money {
          margin-top: size(10);
        }
        span {
          @include font-styles(
            $type: 'label',
            $size: 'md',
            $weight: 'regular'
          );
        }
      }
      .setup-billing-wrapper {
        background-color: var(--global--white--Color);
        border-radius: size(10);
        border: size(1) solid var(--global--line--BackgroundColor);
        box-shadow: 0 size(1) size(3) rgba(0, 0, 0, 0.1),
          0 size(2) size(1) rgba(0, 0, 0, 0.06), 0 size(1) size(1) rgba(0, 0, 0, 0.08);
        padding: size(20);
        margin-top: size(20);
        a {
          color: var(--global--white--Color);
          margin-right: size(6);
          margin-top: size(10);
        }
        h4 {
          @include font-styles(
            $type: 'text',
            $size: 'md',
            $weight: 'semibold'
          );
          margin-bottom: size(10);
          display: inline-block;
          span {
            display: inline-block;
            @include font-styles(
              $type: 'label',
              $size: 'lg',
              $weight: 'regular'
            );
            text-align: center;
            color: var(--global--primary--Color);
            &.verified {
              width: size(14);
              height: size(14);
              margin-left: size(3);
              background: var(--global--success--BackgroundColor);
              border-radius: 50%;
              vertical-align: top;
              margin-top: size(3);
              svg {
                display: inline-block;
                vertical-align: top;
                padding-top: size(3);
                fill: var(--global--white--Color);
                width: size(8);
                height: size(10);
              }
            }
            &.failed {
              margin-left: size(5);
              color: var(--global--error--BackgroundColor);
              svg {
                fill: var(--global--error--BackgroundColor);
              }
              @include font-styles(
                $type: 'label',
                $size: 'md',
                $weight: 'regular'
              );
            }
            &.under-review {
              margin-left: size(5);
              color: var(--global--premium--Color);
              svg {
                fill: var(--global--premium--Color);
              }
              @include font-styles(
                $type: 'label',
                $size: 'md',
                $weight: 'regular'
              );
            }
          }
          svg {
            width: size(16);
            height: size(12);
            fill: var(--global--success--BackgroundColor);
          }
        }
        .edit-btn {
          float: right;
          @include font-styles(
            $type: 'label',
            $size: 'lg',
            $weight: 'regular'
          );
          color: var(--global--common-link--Color);
          margin: 0;
        }
        img {
          height: size(25);
        }
        .payment-details {
          padding-bottom: size(14);
          border-bottom: size(1) solid var(--global--border--Color);
          margin: size(10) 0 size(20);
        }
        .company-details {
          padding-bottom: size(20);
        }
        h5 {
          @include font-styles(
            $type: 'label',
            $size: 'sm',
            $weight: 'regular'
          );
          color: #777777;
          padding-bottom: size(10);
        }
        p {
          @include font-styles(
            $type: 'label',
            $size: 'lg',
            $weight: 'regular'
          );
          padding-bottom: size(6);
          text-transform: capitalize;
          &.info {
            text-transform: none;
          }
          &.billing-date {
            color: #777777;
            @include font-styles(
              $type: 'label',
              $size: 'sm',
              $weight: 'regular'
            );
          }
          &.name {
            font-weight: bold;
            padding-bottom: 0;
          }
          span {
            display: inline-block;
            vertical-align: top;
            padding-right: size(5);
          }
        }
        .email {
          text-transform: lowercase;
          color: var(--global--common-link--Color);
          display: inline-block;
          margin: 0 0 size(8);
          @include font-styles(
            $type: 'label',
            $size: 'lg',
            $weight: 'regular'
          );
        }
      }
      .terms-and-conditions {
        @include font-styles($type: 'label', $size: 'sm', $weight: 'regular');
        span {
          @include font-styles($type: 'label', $size: 'sm', $weight: 'bold');
        }
        margin: size(10) 0 size(40);
      }
    }
  }
  .priority-support {
    &.dual-column {
      margin-bottom: size(30);
    }
    margin-bottom: size(50);
  }
}
</style>
<style lang="scss">
.billing-section {
  .view-bill-btn {
    display: inline-block;
    color: var(--global--common-link--Color);
    padding: size(10) 0;
    margin-right: size(24);
    @include font-styles(
      $type: 'label',
      $size: 'lg',
      $weight: 'regular'
    );
  }
  .loader {
    .loading-placeholder {
      max-width: calc(
          var(--global__console-home--Width) - var(--global__senior-menu--Width)
        );
      padding: unset;
      position: unset;
      top: unset;
      min-height: size(500);
      .loading-placeholder__header.skeleton-box, .side-nav {
        display: none;
      }
      .loading-placeholder__main .left-col {
        height: unset;
        margin-right: unset;
      }
    }
  }
  &.full-width {
    .billing-section__current-status {
      .align-left {
        width: 100%;
        .billing-history {
          max-width: 100%;
          table {
            thead {
              tr {
                th {
                  &:nth-child(2), &:nth-child(3) {
                    width: calc(100% /3);
                  }
                  &:last-child {
                    min-width: size(50);
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>
<script>
import moment from 'moment';
import { mapState } from 'vuex';
import Config from '@/config';
import { currencyMethodsMixin } from '@/mixin/currency';
import BillingHistory from '@/components/Billing/BillingHistory.vue';
import PrioritySupport from '@/components/PrioritySupport.vue';
import LoadingPlaceholder from '@/components/LoadingPlaceholder.vue';

export default {
  name: 'Billing',
  mixins: [currencyMethodsMixin],
  components: {
    BillingHistory,
    PrioritySupport,
    LoadingPlaceholder,
  },
  computed: {
    ...mapState({
      activeProject: (state) => state.project.activeProject,
    }),
    billingTitle() {
      const dateObj = new Date();
      let month = new Date().getMonth();
      const day = dateObj.getUTCDate();
      const year = new Date().getFullYear();
      month = moment.monthsShort(month);
      let newdate = null;
      if (day < 15 || day > 29) {
        newdate = `Billed till ${day} ${month} ${year}`;
      } else {
        newdate = `Unbilled till ${day} ${month} ${year}`;
      }
      return newdate;
    },
  },
  created() {
    if (this.activeProject.loaded) {
      this.fetchData();
      this.fetchUnpaidBills(this.activeProject.key);
      this.fetchCashDetails(this.activeProject.key);
    }
  },

  watch: {
    activeProject(val, old) {
      if (old.key && (val.key !== old.key)) {
        console.log('reached');
        this.fetchData();
      }
    },
  },

  data() {
    return {
      isLoading: true,
      link: Config.sportsHost,
      isPackage: false,
      graphqlLink: `${Config.cricketApiHost}/v5/graphql/`,
    };
  },

  mounted() {
    if (this.activeProject && this.activeProject.project_license
      && this.activeProject.project_license.license_key
      && this.activeProject.project_license.license_key.isPackage) {
      this.isPackage = true;
    }
  },
  methods: {
    fetchUsage(params) {
      this.$store.dispatch('project/fetchProjectUsage', { params }).then(() => {
        console.log('got it');
      }).catch((err) => {
        console.log('fetuch usage error', err);
      });
    },
    fetchUnpaidBills(key) {
      this.$store.dispatch('project/fetchUnpaidBillDetails', { key }).then((resp) => {
        console.log('got it', resp);
      }).catch((err) => {
        console.log('fetuch usage error', err);
      });
    },
    fetchCashDetails(key) {
      this.$store.dispatch('project/fetchCashBalanceDetails', { key }).then((resp) => {
        console.log('got it', resp);
      }).catch((err) => {
        console.log('fetuch usage error', err);
      });
    },
    fetchData(page) {
      this.isLoading = true;
      const params = {
        page: page || 1,
        projectKey: this.activeProject.key,
      };
      this.$store.dispatch('project/fetchInvoiceHistory', { params })
        .then(() => {
          console.log('got it');
          this.isLoading = false;
          const currentYear = new Date().getFullYear();
          let currentMonth = new Date().getMonth() + 1;
          if (currentMonth < 10) {
            currentMonth = `0${currentMonth}`;
          }
          this.activeMonth = `${currentYear}-${currentMonth}`;
          params.month = currentMonth;
          params.year = currentYear;
          this.fetchUsage(params);
        }).catch(() => {
          console.log('error');
        });
    },
  },
};
</script>
